<template>
  <el-drawer :visible.sync="drawer" size="473px" :with-header="false">
    <div class="type">
      <div class="item" v-for="(item, index) in list" :key="index">
        <span :class="[index == typeIndex ? 'active' : '']" @click="typeIndex = index">{{ item.type }}</span>
      </div>
    </div>
    <ul>
      <li v-for="(item, index) in list[typeIndex].list" :key="index" @click="jump(item)">
        <div class="icon">
          <img :src="item.icon" alt="" />
        </div>
        <span>{{ item.name }}</span>
      </li>
    </ul>
  </el-drawer>
</template>

<script>
export default {
  data() {
    return {
      drawer: !1,
      typeIndex: 0,
      list: [
        {
          type: '系统管理',
          list: [
            {
              name: '应用订单',
              path: '/systemManagement/applicationOrder',
              icon: require('@/assets/manage/icon10.png'),
            },
            {
              name: '应用管理',
              path: '/systemManagement/ApplicationManagement',
              icon: require('@/assets/manage/icon15.png'),
            },
            {
              name: '账号管理',
              path: '/systemManagement/AccountManagement',
              icon: require('@/assets/manage/icon13.png'),
            },
            {
              name: '菜单管理',
              path: '/systemManagement/menu',
              icon: require('@/assets/manage/icon4.png'),
            },
            {
              name: '装修审核',
              path: '/systemManagement/diyExamine',
              icon: require('@/assets/manage/icon14.png'),
            },
            {
              name: '版本管理',
              path: '/systemManagement/version',
              icon: require('@/assets/manage/icon1.png'),
            },
            {
              name: '套餐管理',
              path: '/systemManagement/meal',
              icon: require('@/assets/manage/icon8.png'),
              query: {
                type: '1',
              },
            },
            // {
            //   name: '套餐行业',
            //   path: '/systemManagement/meal',
            //   icon: require('@/assets/manage/icon9.png'),
            //   query: {
            //     type: '2',
            //   },
            // },
            {
              name: '充值记录',
              path: '/systemManagement/rechargeLog',
              icon: require('@/assets/manage/icon5.png'),
            },
            {
              name: '帮助管理',
              path: '/systemManagement/helpCenter',
              icon: require('@/assets/manage/icon3.png'),
            },
            {
              name: '公告列表',
              path: '/systemManagement/noticeList',
              icon: require('@/assets/manage/icon6.png'),
            },
            {
              name: '营销组件',
              path: '/systemManagement/marketingComponent',
              icon: require('@/assets/manage/icon12.png'),
              query: {
                type: '1',
              },
            },
            // {
            //   name: '营销分类',
            //   path: '/systemManagement/marketingComponent',
            //   icon: require('@/assets/manage/icon11.png'),
            //   query: {
            //     type: '2',
            //   },
            // },
          ],
        },
        {
          type: '官网管理',
          list: [
            {
              name: '官网新闻',
              path: '/systemManagement/websiteArticle',
              icon: require('@/assets/manage/icon19.png'),
            },
            {
              name: '官网banner',
              path: '/systemManagement/websiteBanner',
              icon: require('@/assets/manage/icon17.png'),
            },
            {
              name: '官网案例',
              path: '/systemManagement/websiteCase',
              icon: require('@/assets/manage/icon18.png'),
            },
            {
              name: '表单收集',
              path: '/systemManagement/formCollection',
              icon: require('@/assets/manage/icon16.png'),
            },
          ],
        },
        {
          type: '其他管理',
          list: [
            {
              name: '小程序认证',
              path: '/systemManagement/appletAuthentication',
              icon: require('@/assets/manage/icon20.png'),
            },
          ],
        },
      ],
    };
  },
  methods: {
    jump(row) {
      this.drawer = !1;
      if (row.query) {
        this.$router.push({
          path: row.path,
          query: row.query,
        });
      } else {
        this.$router.push(row.path);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.type {
  display: flex;
  font-size: 18px;
  margin-top: 40px;
  .item {
    flex: 1;
    display: flex;
    justify-content: center;
    height: 30px;
    span {
      cursor: pointer;
    }
    .active {
      font-weight: bold;
      border-bottom: 4px solid #1890ff;
    }
  }
}
ul {
  display: flex;
  flex-wrap: wrap;
  margin-top: 36px;
  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 155px;
    margin-bottom: 40px;
    cursor: pointer;
    img {
      width: 48px;
      height: 48px;
    }
    span {
      font-size: 16px;
      margin-top: 13px;
    }
  }
}
</style>
