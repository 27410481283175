<template>
  <div class="page-header">
    <div class="page-name">
      <span></span>
      {{ pageName }}
    </div>
    <div>
      <el-button type="primary" size="small" class="el-icon-plus" @click="$refs.manage.drawer = !0">系统管理</el-button>
      <el-button size="small" icon="el-icon-arrow-left" @click="$router.push('/shop')">返回</el-button>
    </div>
    <Manage ref="manage"></Manage>
  </div>
</template>

<script>
import Manage from '@/components/manage';
export default {
  components: {
    Manage,
  },
  props: ['pageName'],
};
</script>

<style lang="less" scoped>
.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  .page-name {
    display: flex;
    align-items: center;
    span {
      height: 20px;
      width: 5px;
      margin-right: 10px;
      background: #1890ff;
    }
  }
  .el-button {
    font-size: 14px;
  }
}
</style>
